@media screen and (min-width: 641px) {
    .landing {
        /* Dunno why I need to do this but I uh...I do */
        margin-bottom: 100px !important;
    }
}



/* --------LANDING TOP-------- */

.landing-top {
    height: calc(100vh - 64px);
    margin-bottom: 4em;
}

.landing-top h1 {
    font-size: 5.5em;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 15vh;
    font-family: 'Patua One', cursive;
    text-shadow: 5px 5px 5px rgba(0,0,0,0.5);
}

.landing h1 span:last-child {
    margin: -0.4em 0 0 0.5em;
}

.landing-intro {
    width: 100%;
    height: 100%;
    font-size: 1.5em;
    text-shadow: 5px 5px 5px rgba(0,0,0,0.5);
    position: relative;
    background-size: 80%;
    background-position: center right;
    background-repeat: no-repeat;
    padding-top: 5em;
}

.landing-intro p {
    margin-right: 50%;
}

@media screen and (max-width: 640px) {
    .landing h1 {
        position: relative;
        z-index: 5;
        margin-bottom: 0;
        font-size: 3em;
        flex-direction: row;
    }

    .landing h1 span:last-child {
        margin: 0 0 0 0.25em;
    }

    .landing-intro {
        font-size: 1.25em;
        width: 100%;
        z-index: 5;
        position: relative;
        margin-top: 0;
        background-position: top center;
        padding-top: 40vh;
    }

    .landing-intro h1 {
        margin-top: 0;
    }

    .landing-intro p {
        margin: 0.5em 0;
    }
}

#tsparticles {
    position: absolute;
    top: 64px;
    right: 0;
    width: 100vw;
    height: calc(300vh - 64px);
    z-index: -10;
}

@media screen and (max-width: 640px) {
    /* move everything up */
    .landing-selected {
        margin-top: -100px !important;
    }
    
    .landing-current {
        margin-top: 0px !important;
    }

    .landing-about {
        margin-top: 0px !important;    
    }
}




/* --------LANDING SELECTED-------- */
.landing-selected {
    margin-bottom: 4em;
}
.selected-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5em;
    margin-bottom: 2em;
}
@media screen and (max-width: 640px) {
    .selected-grid {
        grid-template-columns: 1fr;
    }
}
.selected-project {
    display: block;
    text-decoration: none;
    height: 18em;
    background-size: cover;
    background-position: center;
}
.selected-project-content {
    background-image: linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0));
    background-position: 0% 8em;
    background-size: 200% 100%;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    padding: 1em;
    cursor: pointer;
    text-shadow: 5px 5px 5px rgba(0,0,0,0.5);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-shadow: 5px 5px 5px rgba(0,0,0,0.25);
}
@media screen and (min-width: 641px) {
    .selected-project-content {
        transition: all 0.3s;
    }
    .selected-project-content:hover {
        background-position: 0% 18em;
        text-shadow: 5px 5px 5px rgba(0,0,0,0.75);
    }
}
.selected-project-content h2 {
    margin: 0;
}
.selected-project-content h4 {
    margin: 0;
    width: 80%;
}
.landing-selected .see-all {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 1.2em;
}

/* -------LANDING PSST-------- */
.landing-psst {
    margin-bottom: 8em;
    margin-top: 8em;
    display: flex;
    justify-content: center;
    font-size: 1.2em;
    font-style: italic;
}

.landing-psst a {
    margin-left: 0.4em;
    color: #db5680;
    font-weight: bold;
}




/* --------LANDING CURRENT-------- */
.landing-current {
    margin-top: 4em;
}
.landing-current-sidebyside {
    display: flex;
}
.landing-current-sidebyside > div {
    width: 50%;
}
.landing-current-sidebyside > div:first-child {
    margin-right: 1em;
}
.landing-current h1 {
    margin-bottom: 0;
}
@media screen and (max-width: 640px) {
    .landing-current-sidebyside {
        flex-direction: column;
    }
    .landing-current-sidebyside > div {
        width: 100%;
        margin: 0 0 2em 0;
    }
}
.current-effort {
    display: grid;
    text-decoration: none;
    place-items: center left;
    height: 10em;
    background-color: rgba(0,0,0,0.5);
    padding: 0.5em 1em;
    margin-bottom: 1em;
}
.current-effort h3 {
    margin: 0;
}
.current-effort p {
    margin: 0;
}
.current-effort .effort-type {
    margin-top: 0.5em;
}
.recent-entry {
    height: 10em;
    background-size: cover;
    background-position: center;
    margin-bottom: 1em;
}
.recent-entry-inner {
    text-decoration: none;
    display: grid;
    place-items: center left;
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    padding: 0.5em 1em;
    text-shadow: 5px 5px 5px rgba(0,0,0,0.5);
}
.recent-entry h3, .recent-entry p {
    margin: 0;
}
.recent-entry-preview {
    margin-top: 0.5em;
    width: 100%;
    overflow-y: hidden;
    height: 2.6em;
}
.recent-entry-preview p {
    width: 100%;
    word-wrap: break-word;
    position: relative;
}
.current-project {
    height: 10em;
    background-size: cover;
    margin-bottom: 1em;
}
.current-project a {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    height: 100%;
    width: 100%;
    padding: 0.5em 1em;

    text-decoration: none;
    text-shadow: 5px 5px 5px rgba(0,0,0,0.5);
}
.current-project h2, .current-project p {
    margin: 0;
}

@media screen and (min-width: 641px) {
    .current-effort, .recent-entry-inner {
        cursor: pointer;
        transition: all 0.3s;
    }
    .current-effort:hover, .recent-entry-inner:hover {
        background-color: rgba(0,0,0,0.1);
    }
}





/* --------LANDING ABOUT-------- */
.landing-about {
    margin-top: 300px;
}
.landing-about h1 {
    margin-bottom: 0;
}
.landing-about-sidebyside {
    display: flex;
}
.landing-about-sidebyside > div {
    width: 50%;
}
.landing-about-sidebyside > div:last-child {
    display: grid;
    place-items: center;
}
.landing-about img {
    width: 80%;
    height: auto;
}
@media screen and (max-width: 640px) {
    .landing-about-sidebyside {
        flex-direction: column;
    }
    .landing-about-sidebyside > div {
        width: 100%;
        margin-bottom: 2em;
    }
}