.next-prev-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
    gap: 1.5em;
    padding-top: 2em;
    margin: 2rem 0;
    padding-bottom: 2rem;
}

@media screen and (max-width: 640px) {
    .next-prev-container {
        grid-template-columns: 1fr;
    }
}

.entry-detail-project-header {
    width: 100%;    
    background-size: cover;
    background-position: center;
}

.entry-detail-project-header a {
    display: block;
    color: #fff;
    text-decoration: none;
    padding: 2em calc((100% - 1000px) * 0.5);
    background-color: rgba(0,0,0,0.2);
    text-shadow: 2px 2px rgba(0,0,0,0.5);
    box-shadow: 5px 5px 5px rgba(0,0,0,0.3);
    transition: all 0.3s;
}

@media screen and (max-width: 640px) {
    .entry-detail-project-header a {
        padding-left: 1em;
    }
}

@media screen and (min-width: 641px) {
    .entry-detail-project-header a:hover {
        background-color: rgba(0,0,0,0.5);
        box-shadow: 3px 8px 7px rgba(0,0,0,0.5);
        padding-left: calc((100% - 1000px) * 0.5 + 50px);
    }
}

.next-prev-container .no-more {
    display: grid;
    place-items: center;
    font-size: 0.8em;
    color: rgba(255,255,255,0.6);
}

.entry-effort-indicator {
    border-top: 1px solid;
    border-bottom: 1px solid;
    padding: 1.5em;
}

.entry-effort-indicator p:first-child {
    font-size: 1.4em;
    font-weight: bold;
}

.entry-effort-indicator p {
    margin: 0;
}