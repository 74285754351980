.image-gallery {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.gallery-image-active {
    display: grid;
    place-items: center;
    margin: 1em 0;
}

.gallery-image-active div {
    display: grid;
    place-items: center;
    width: 100%;
    /* height: 500px; */
}

/*
.gallery-image-active img {
    max-height: 500px;
    max-width: 650px;
}
*/

.image-gallery-container figcaption {
    /* max-width: 650px; */
    text-align: center;
    font-style: italic;
    min-height: 2em;
    margin: 0 auto;
    margin-bottom: 1em;
}

.gallery-image-inactive {
    display: grid;
    place-items: center;
    margin: 0.3em;
    /* height: 150px;
    width: 150px; */

    cursor: pointer;

    transition: all 0.3s;
    border: 1px solid rgba(255,255,255,0);
}

.gallery-image-selected {
    border: 2px solid white !important;
}

/*
.gallery-image-inactive img {
    max-height: 145px;
    max-width: 145px;
}
*/

.gallery-image-inactive:hover {
    border: 1px solid rgba(255,255,255,1);
}