.loading-container {
    width: 100%;
    display: grid;
    place-items: center;
}

.loading-inner {
  display: grid;
  place-items: center;
}

.loading-spinner {
    border: 16px solid #B294E6; /* Light grey */
    border-top: 16px solid #fff; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 0.5s linear infinite;
  }

  .loading-container div p {
    text-align: center;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }