.project-tile {
    background-size: cover;
    background-position: center center;
    height: 200px;
}

.project-tile a {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    height: 200px;
    width: 100%;
    padding: 0 40px;

    text-decoration: none;
}

.project-tile h2 {
    margin: 0;
    margin-bottom: 0 !important;
    text-shadow: 2px 2px rgba(0,0,0,0.8);
}

.project-tile p, .project-tile strong {
    margin: 0;
    text-shadow: 2px 2px rgba(0,0,0,0.8);
}

@media screen and (max-width: 640px) {
    .project-tile .project-description {
        display: none;
    }
}