.dashboard header {
    margin-bottom: 2em;
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 640px) {
    .dashboard header {
        flex-direction: column;
    }

    .dashboard header div:first-child {
        margin-bottom: 1em;
    }
}

.dashboard header h1 {
    margin: 0;
}

.dashboard header > div:first-child {
    margin: 0;
    width: 80%;
}

.dashboard header a {
    display: inline-block;
    margin: 0.5em 1em 0 0;
    font-size: 1.2em;
}

.dashboard h2 {
    margin: 0;
}

.dashboard-project-table {
    width: 100%;
    border-spacing: 0;
    margin-bottom: 2em;
}

.dashboard-project-table th {
    height: 1.5em;
    border-bottom: 1px solid white;
    text-align: left;
    font-size: 1.4em;
    padding: 0.5em;
}

.dashboard-project-table thead span {
    margin-left: 1em;
}

.dashboard-project-table td {
    font-size: 1.2em;
    cursor: pointer;
    padding: 0.5em;
}

@media screen and (max-width: 640px) {
    .dashboard-project-table th {
        font-size: 1.2em;
    }
    .dashboard-project-table td {
        font-size: 1em;
        padding: 0.2em;
    }
}

.dashboard-project-table tr:nth-child(even) {
    background-color: rgba(0,0,0,0.2);
}

@media screen and (min-width: 641px) {
    .dashboard-project-table tbody tr, .dashboard-project-table thead th {
        background-color: rgba(0,0,0,0);
        transition: all 0.3s;
    }

    .dashboard-project-table tbody tr:hover, .dashboard-project-table thead th:hover {
        background-color: rgba(0,0,0,0.4);
    }
}

.dashboard-buttons {
    margin-bottom: 1em;
}

.dashboard-buttons a {
    margin-right: 2em;
}