.entry-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-size: cover;
    background-position: center center;
    height: 200px;
}

.entry-tile a.hover-tile {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;

    padding: 20px 40px;
    height: 200px;
    width: 100%;

    text-decoration: none;
    
    overflow-y: hidden;
}

.entry-tile a h4 {
    margin: 0 0 0 0;
    border-bottom: 1px solid white;
    text-shadow: 2px 2px rgba(0,0,0,0.8);
}

.entry-tile-date {
    margin: 0 0 1em 0;
    font-style: italic;
}

.entry-tile-content p {
    margin: 0;
    text-shadow: 2px 2px rgba(0,0,0,0.8);
}