.entry-week-heading {
    text-align: right;
    border-bottom: 1px solid white;
}

.entry-week-heading h2 {
    margin: 0;
}

.entry-week-heading h4 {
    margin-top: 0;
    margin-bottom: 0.75em;
}

.entry-day > h3 {
    font-size: 1.3rem;
    margin-bottom: 0;
}

.entry-day > p {
    margin-top: 0;
    font-style: italic;
}

.entry-day a:after {
    content: ",";
    margin-right: 0.3em;
}

.entry-day a:last-child:after {
    content: "" !important;
}

.more-entries-container {
    text-align: center;
}

.more-entries-button {
    border: 0;
    padding: 1em 2em;
    margin-top: 2em;
    font-size: 1.2em;
}