.project-tile-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
    gap: 1.5em;
    padding-top: 2em;
}

@media screen and (max-width: 640px) {
    .project-tile-container {
        grid-template-columns: 1fr;
    }
}

.project-group {
    padding-bottom: 2rem;
}

.project-group h2 {
margin-bottom: -1rem;
}

.projects-intro p {
    margin: 0;
    font-style: italic;
    font-size: 1.2em;
}

.filters {
    margin-bottom: 4em;
}

.filters > div > div {
    display: grid;
    grid-gap: 2em;
}

@media screen and (max-width: 640px) {
    .filters > div > div {
        display: flex;
        flex-direction: column;
    }
}

.filters > div > div > div > ul {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr;
    grid-gap: 1em;
    padding-top: 1em;
}

.filters .one {
    grid-column: 1/1;
}

.filters .three {
    grid-column: 2/3;
}

.filters .one:last-child {
    grid-column: 3/3;
}

.filters .three > ul {
    grid-template-columns: 1fr 1fr 1fr;
}

.filters ul {
    padding: 0;
    list-style: none;
    margin: 0;
}

.filters li {
    text-align: center;
    font-size: 1.2em;
    background-color: rgba(0,0,0,0.5);
    height: 2em;
    display: grid;
    place-items: center;
    cursor: pointer;
}

.filters h2, .filters h3 {
    text-align: center;
    margin-bottom: 0;
}

.filters h2 {
    padding: 0.25em 0;
    cursor: pointer;
}

.filters h3 {
    border-bottom: 1px solid white;
    padding-bottom: 0.25em;
    width: 60%;
    margin: auto;
    margin-top: 0.5em;
}

.filters p {
    text-align: center;
    font-size: 1.2em;
}

.filters p > span {
    margin-left: 0.25em;
    cursor: pointer;
    padding: 0.5em 1em;
}

.active-filter {
    border: 2px solid white;
    background-color: rgba(0,0,0,0.8) !important;
}

.match-button-container {
    width: 100%;
    text-align: center;
    margin-top: 1.5em;
    margin-bottom: 1em;
}

.match-button {
    border: 0;
    padding: 0.5em 1em;
    font-size: 1.2em;
    margin: 0 auto;
    cursor: pointer;
}