.entryToLabel > ul {
    display: flex;
    justify-content: space-between;
    padding: 0;
}

.entryToLabel > ul > li {
    display: grid;
    place-items: center;
    width: 100%;
    border-radius: 5px;
    margin: 0.5em;
    background-color: rgba(0,0,0,0.1);
    box-shadow: 5px 5px 5px rgba(0,0,0,0.2);
    padding: 0.5em;
    cursor: pointer;
    transition: all 0.3s;
}

.entryToLabel > ul > li:hover {
    background-color: rgba(50, 50, 50, 0.1);
    box-shadow: 10px 10px 10px rgba(0,0,0,0.3);
}