.timeline-container {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
}

.timeline-project {
    display: inline-block;
}

.timeline-line {
    position: absolute;
    width: 1px;
    background-color: #fff;
    transform: translateX(10px);
    transition: all 0.3s;
}

.timeline-entry {
    display: block;
    position: absolute;
    height: 10px;
    width: 20px;
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 30%;
    z-index: auto;
    transition: all 0.3s;
}

.timeline-entry:hover {
    background-color: #fff !important;
    z-index: 2;
    width: 30px;
    transform: translateX(-5px);
}

.timeline-hover-panel {
    position: absolute;
    z-index: 5;
    background-color: #333333;
    box-shadow: 2px 5px 5px rgba(0,0,0,0.5);
    padding: 0.5em 1em;
    width: 300px;

    transition: all 0.3s;
}

.timeline-hover-panel h3 {
    margin: 0;
    border-bottom: 1px solid white;
}

.timeline-hover-panel h4 {
    margin: 0;
    margin-bottom: 0.2em;
}

.timeline-hover-panel p {
    margin: 0;
    font-style: italic;
}

.timeline-project-title-top, .timeline-project-title-bottom {
    position: absolute;
    text-align: left;
    margin: 0;
    transition: all 0.3s;
}

@media screen and (max-width: 640px) {
    .timeline-project-title-top, .timeline-project-title-bottom {
        font-size: 0.8em;
    }
}

.timeline-project-title-top {
    transform-origin: center right;
    transform: translateX(20px) translateY(-20px) rotate(40deg);
}

.timeline-project-title-bottom {
    transform-origin: center left;
    transform: translateX(5px) translateY(10px) rotate(40deg);
}

.timeline-month-marker {
    position: absolute;
    width: 100%;
    border-bottom: 1px solid rgba(255,255,255,0.1);
    height: 1px;
    z-index: -1;
}

.timeline-month-marker p {
    margin: 0;
    /* transform: translateY(-1.25em); */
    font-weight: normal;
    font-size: 1.5em;
}