:root {
    --label-width: 15%;
    --input-width: 85%;
}

@media screen and (max-width: 640px) {
    :root {
        --label-width: 100%;
        --input-width: 100%;
    }
}

.form-element {
    padding: 0.5em 0;
}

.form-element div span {
    display: inline-block;
    width: var(--label-width);
    margin: 0;
    font-size: 1.2em;
    text-align: right;
    padding: 0.5em 1em 0 0;
}

.form-element div input, 
.form-element div select, 
.form-element .checkbox-input-wrapper {
    display: inline-block;
    width: var(--input-width);
    font-size: 1.2em;
    padding: 0.5em;
}

.checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-top: 0.5em !important;
}

.checkbox-wrapper span {
    padding: 0 1em 0.2em 0 !important;
}

.form-element .checkbox-input-wrapper {
    padding: 0 !important;
}

.form-element .checkbox-input-wrapper input {
    width: 1.4em;
    height: 1.4em;
    margin: 0;
}

@media screen and (max-width: 640px) {
    .form-element div span {
        display: block;
        text-align: left;
    }
}

.form-element ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: var(--input-width);
    margin-left: var(--label-width);
}

.form-element ul li {
    font-size: 1.2em;
    padding: 0.25em 0.5em;
    background-color: #111;
}

.form-element ul li.suggestion-active {
    background-color: #333 !important;
}

.form-element textarea {
    width: var(--input-width);
    font-size: 1.2em;
    padding: 0.5em;
    height: 33em;
}

.form-element .textarea-wrapper, .form-element .images-wrapper {
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 640px) {
    .form-element .textarea-wrapper, .form-element .images-wrapper {
        display: block;
    }
}

.react-datepicker-wrapper {
    width: var(--input-width) !important;
}
.react-datepicker-wrapper div {
    width: 100% !important;
}
.react-datepicker-wrapper input {
    width: 100% !important;
}

.images-container {
    width: var(--input-width);
}


.images-item, .image-item-single {
    width: 100%;
    display: grid;
    grid-gap: 0.5em;
    margin-bottom: 1em;
}
.images-item {
    grid-template-columns: 14fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 0.5fr;
}

.image-item-single {
    grid-template-columns: 15fr 1fr;
    grid-template-rows: 1fr 0.5fr;
}

.images-item div:first-child, .image-item-single div:first-child {
    grid-row: 1 !important;
}
.images-item div.nocaption:first-child {
    grid-row: 1/3 !important;
    display: grid;
    place-items: center;
}
.images-item div {
    grid-column: 1;
    grid-row: 2;
}
.images-item button {
    margin-top: 0 !important;
    padding: 0.5em !important;
}
.images-item button.cancel {
    grid-column: 2;
    grid-row: 1 / span 2;
}
.image-item-single button.cancel {
    grid-column: 2;
    grid-row: 1;
    margin: 0;
}
.images-item button.up {
    grid-column: 3;
    grid-row: 1;
}
.images-item button.down {
    grid-column: 3;
    grid-row: 2;
}
.images-item input, .image-item-single input {
    width: 100% !important;
}

.images-item .form-element, .image-item-single .form-element {
    padding: 0;
    display: grid;
    place-items: center;
    width: 100%;
}

.images-item .form-element .input-wrapper, .image-item-single .form-element .input-wrapper {
    width: 100%;
}

.images-item .error, .image-item-single .error {
    padding: 0 !important;
    width: 100%;
    text-align: left;
}

.images-container button {
    margin: 0.5em 0 0 0;
    padding: 0.5em 2em;
    font-size: 1.2em;
    background-color: rgba(0,0,0,0.4);
    color: #fff;
    border: none;
    box-shadow: 2px 5px 5px rgba(0,0,0,0.3);
    cursor: pointer;

    transition: 0.3s all;
}

.images-container button:hover {
    box-shadow: 2px 8px 8px rgba(0,0,0,0.4);
    background-color: rgba(0,0,0,0.6);
}

.images-item .upload-progress-container, .image-item-single .upload-progress-container {
    width: 100%;
    height: 1.5em;
    position: relative;
    border: 1px solid white;
    border-radius: 0.25em;
}

.images-item .upload-progress-container {
    grid-column: 1 / span 3;
    grid-row: 3 / 3;
}

.image-item-single .upload-progress-container {
    grid-column: 1 / span 2;
    grid-row: 2 / 2;
}

.upload-progress-container .upload-progress-bar, .image-item-single .upload-progress-bar {
    position: absolute;
    height: 100%;
    width: 0%;
    background-color: #B294E6;
    transition: width 0.3s;
}

.upload-progress-container p {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    text-align: center;
    color: #fff;
    z-index: 200;
    margin: 0em 0 0 0;
}

.images-item .dropzone, .image-item-single .dropzone {
    border: 2px dashed #666;
    text-align: center;
}

.form-element .buttons-wrapper {
    padding-left: var(--label-width);
    margin-top: 2em;
}

.form-element .buttons-wrapper button {
    padding: 0.5em 2em;
    font-size: 1.4em;
    background-color: rgba(0,0,0,0.4);
    color: #fff;
    border: none;
    box-shadow: 2px 5px 5px rgba(0,0,0,0.3);
    cursor: pointer;
    margin-right: 2em;
}

@media screen and (max-width: 640px) {
    .form-element .buttons-wrapper {
        padding: 0;
    }

    .form-element .buttons-wrapper button {
        margin-bottom: 1em;
    }    
}

.button-danger {
    background-color: rgba(100,30,30,0.4) !important;
}

@media screen and (min-width: 641px) {
    .form-element .buttons-wrapper button:hover {
        box-shadow: 2px 8px 8px rgba(0,0,0,0.4);
        background-color: rgba(0,0,0,0.6);
        transition: 0.3s all;
    }

    .button-danger:hover {
        background-color: rgba(150,30,30,0.6) !important;
    }
}

.form-element .error {
    padding-left: var(--label-width);
    color: rgb(240, 117, 117);
}

@media screen and (max-width: 640px) {
    .form-element .error {
        padding: 0;
    }

    
}

.expand-wrapper span:last-child {
    padding: 0.5em !important;
    width: 4em;
    text-align: center;
    cursor: pointer;
}
.content-previewable-wrapper {
    display: flex;
}

.content-previewable-wrapper .form-element {
    width: var(--input-width) !important;
}

.content-previewable-wrapper .form-element textarea {
    width: 100% !important;
    font-size: 1.2em;
    padding: 0.5em;
    height: 33em;
}

.content-previewable-label {
    width: var(--label-width);
    display: flex;
    flex-direction: column;
    font-size: 1.2em;
    text-align: right;
    padding: 0.5em 1em 0 1em;
}

.content-previewable-label span {
    margin-bottom: 1em;
}

.content-previewable-label button {
    padding: 0.5em;
    font-size: 1em;
    background-color: rgba(0,0,0,0.4);
    color: #fff;
    border: none;
    box-shadow: 2px 5px 5px rgba(0,0,0,0.3);
    cursor: pointer;
}

.content-image-previews {
    display: flex;
    justify-content: center;
    height: 8em;
}

.content-image-previews img {
    height: 100%;
    width: auto;
    margin: 0 0.5em;
}

@media screen and (max-width: 640px) {
    .content-previewable-wrapper {
        flex-direction: column;
    }

    .content-previewable-label {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-left: 0;
    }

    .content-previewable-label span {
        margin-bottom: 0;
    }
}

.content-preview {
    padding: 0.5em;
    background-color: #333;
    width: var(--input-width);
}

.content-preview *:first-child {
    margin-top: 0.25em;
}

.tags-wrapper {
    margin-bottom: 1em;
}

.tags-wrapper button {
    margin-left: var(--label-width);
    border: none;
    padding: 0.5em 1em;
    font-size: 1.2em;
    cursor: pointer;
}

.tags-wrapper .tags-display {
    margin-left: var(--label-width);
    display: flex;
    flex-wrap: wrap;
    margin-top: -0.5em;
    height: 4em;
}

.tags-display > .tag-item {
    margin-right: 1em;
    padding: 0.25em 0.75em 0.4em 0.75em;
    background-color: #111;
    border-radius: 1em;
}

.tag-item > span:last-child {
    margin-left: 0.5em;
    cursor: pointer;
}

.radio-wrapper .label {
    display: inline-block !important;

}

.radio-wrapper .radio-options {
    display: inline-flex !important;
}

.radio-options .radio-option input {
    display: none !important;
}

.radio-options .radio-option label {
    width: 5rem;
    height: 5rem;
    display: grid;
    place-items: center;
    font-size: 2rem;
    transition: all 0.3s;
    cursor: pointer;
}

.radio-options .radio-option label:hover {
    background-color: rgba(0,0,0,0.2);
}

.option-note .radio-options .radio-option:nth-child(1),
.option-elaboration .radio-options .radio-option:nth-child(2),
.option-experimentation .radio-options .radio-option:nth-child(3) {
    background-color: rgba(0,0,0,0.5);
    box-shadow: 5px 5px 5px rgba(0,0,0,0.25);
}