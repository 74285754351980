.entry-list-item {
    margin-bottom: 2em;
}

.entry-list-item > h4 {
    margin-top: 0.5rem;
    margin-bottom: 0.25rem;
    font-size: 1.1rem;
}

.entry-list-item-content > p:first-child {
    margin-top: 0;
}

.entry-list-item-page-link {
    display: block;
    width: 100%;
    text-align: center;
    padding: 1em 0;
    font-size: 1.2em;
}

.entry-list-item .image-gallery-container {
    margin-top: 2em;
}