.project-detail-nav {
    height: 64px;
    margin-left: -9999em;
    padding-left: 9999em;
    margin-right: -9999em;
    padding-right: 9999em;
    background-color: #333;
    position: relative;
    z-index: 5;
    box-shadow: 5px 5px 5px rgba(0,0,0,0.25);
    margin-bottom: 2em;
}

.project-detail-nav .container {
    height: 100%;
    padding: 0;
}

.project-detail-nav ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    height: 100%;
    margin: 0;
}

.project-detail-nav ul li {
    display: block;
    text-align: center;
    height: 100%;
}

.project-detail-nav ul li a {
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;
    text-decoration: none;
    padding: 0 1em;
    font-size: 1.2em;
}

@media screen and (max-width: 640px) {
    .project-detail-nav ul {
        justify-content: space-around;
    }

    .project-detail-nav ul li a {
        font-size: 0.8em;
        padding: 0 0.5em;
    }
}

.project-detail-nav ul li a:hover {
    background-color: rgba(0,0,0,0.3);
}

.project-detail-nav li.nav-selected {
    background-color: #111;
}

.project-detail-header-wrapper {
    width: 100%;
    margin-top: -2em;
    height: 200px;
    box-shadow: 5px 5px 5px rgba(0,0,0,0.3);
    position: relative;
    z-index: 50;
    background-size: cover;
    background-position: center;
}

.project-detail-header {
    width: 100%;
    height: 100%;
    padding: 2em calc((100% - 1000px) / 2);
    display: flex;
    align-items: center;
    text-shadow: 2px 2px rgba(0,0,0,0.5);
    background-color: rgba(0,0,0,0.4);
}

.project-detail-header h1 {
    font-size: 3em;
    margin-bottom: 0;
}

.project-detail-header p {
    margin-top: 0;
    font-size: 1.5em;
}

@media screen and (max-width: 640px) {
    .project-detail-header {
        padding-left: 1em;
    }

    .project-detail-header h1 {
        font-size: 2em;
    }

    .project-detail-header p {
        font-size: 1.2em;
    }
}

.project-detail-body .image-gallery-container {
    margin-top: 4em;
}

.entry-tile-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
    gap: 1.5em;
    padding-top: 2em;
}

@media screen and (max-width: 640px) {
    .entry-tile-container {
        grid-template-columns: 1fr;
    }
}

.project-detail-gallery {
    display: flex;
    flex-wrap: wrap;
}

.project-gallery-content-wrapper {
    background-size: cover;
    background-position: center;

    position: relative;
    flex-basis: calc(33% - 2em);
    margin: 1em;
}

@media screen and (max-width: 640px) {
    .project-gallery-content-wrapper {
        background-size: cover;
        background-position: center;
    
        position: relative;
        flex-basis: calc(100% - 2em);
        margin: 1em;
    }
}

.project-gallery-content-wrapper::before {
    content: '';
    display: block;
    padding-top: 100%;
}

.project-gallery-content {
    position: absolute;
    top: 0; left: 0;
    height: 100%;
    width: 100%;

    display: flex;               /* added for centered text */
    justify-content: center;     /* added for centered text */
    align-items: center;         /* added for centered text */
}

@media screen and (min-width: 641px) {
    .project-gallery-content {
        transform: scale(1,1);
        box-shadow: 0 0 0 0 rgba(0,0,0,0);
        transition: all 0.5s;
    }

    .project-gallery-content:hover {
        transform: scale(1.025, 1.025);
        box-shadow: 0 10px 10px 0 rgba(0,0,0,0.25);
    }
}

.project-gallery-content img, .project-gallery-content video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.entry-container {
    font-size: 1.2em;
}

.entry-item {
    display: flex;
    justify-content: space-between;
    height: 2.4em;
    padding: 0.5em 0;
    text-decoration: none;
    position: relative;
    font-size: 1.2em;
    padding-right: 1em;
}

.entry-item p {
    margin: 0;
}

.entry-item > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.entry-item .circle {
    display: inline-block;
    height: 1.2em;
    width: 1.2em;
    border-radius: 50%;
    box-shadow: 5px 5px 5px rgba(0,0,0,0.25);
}

.entry-item div .summary {
    display: inline-block;
    margin-left: 2em;
}

.entry-item div .date {
    display: block;
}

.version-span {
    height: 3.6em;
}

@media screen and (max-width: 640px) {
    .entry-item {
        height: 3.6em;
    }
    .entry-item > div:first-child > p {
        max-width: 70%;
    }

    .version-span {
        height: 6em;
    }
    
    .version-span > div > div {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (min-width: 641px) {
    .entry-item {
        background-color: rgba(0,0,0,0);
        transition: all 0.3s;
    }

    .entry-item:hover {
        background-color: rgba(0,0,0,0.3);
    }

    .entry-item div .summary {
        transition: all 0.3s;
    }

    .entry-item:hover div .summary {
        margin-left: 3em;
    }
}

.entry-item .line, .effort-item .line {
    position: absolute;
    height: 2.4em;
    width: 2px;
    top: 0;
    left: 0.55em;
    z-index: -1;
}

.effort-item .line {
    left: -2px;
}

.effort-item {
    height: 3.6em;
    font-size: 1.2em;
    margin-left: 0.55em;
    padding-left: 1.65em;
    border-left: 2px solid white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding-right: 1em;
}
@media screen and (max-width: 640px) {
    .effort-item {
        height: 6em;
    }
}

.effort-item p {
    margin: 0;
}

.effort-item div p:first-child {
    font-weight: bold;
}

.effort-entry {
    margin-left: 2em !important;
}





/* Downloads */

.download {
    margin-bottom: 4em;
    background-color: rgba(0,0,0,0.2);
    padding: 2em;
}

.download h3 {
    margin: 0;
}
.download > h3 a {
    text-decoration: none;
}
.download > h3 a:hover {
    text-decoration: underline;
}
.download > h3 a:last-child {
    margin-left: 0.25em;
}

.download > p {
    margin: 0;
    font-style: italic;
}

.older-downloads {
    display: grid;
    grid-template-rows: 1fr;
    grid-gap: 1em;
}
.older-downloads > a {
    text-decoration: none;
    display: flex;
    justify-content: space-between;
}
.older-downloads > a > p {
    margin: 0.5em 0;
}

.download .files h3 {
    margin-bottom: 0.5em;
}

.download .files ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.download .files li {
    border: 1px solid rgba(255,255,255,0.1);
    border-bottom: 0;
}

.download .files li:last-child {
    border-bottom: 1px solid rgba(255,255,255,0.1);
}

.download .files li > a {
    height: 100%;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    padding: 0 1em;
    font-weight: 700;
}

.download .files li > a:hover {
    text-decoration: underline;
}