@import url('https://fonts.googleapis.com/css2?family=Patua+One&display=swap');

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background-color: #222;
    color: #fff;
}

html, body {
    max-width: 100%;
    overflow-x: hidden;
    min-height: 100vh;
}

#root, .App {
    min-height: 100vh;
}

.main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 64px - 2em);
}

p a {
    color: #A5F8D3;
}

a {
    color: #fff;
}

a:visited {
    color: auto;
}

.container {
    width: 1000px;
    margin: 0 auto;
    flex: 1 0 auto;
}

@media screen and (max-width: 640px) {
    .container {
        width: auto;
        margin: 0;
        padding: 0 1em;
        /* padding-right: 2em; */
    }
}

.hidden {
  display: none;
}

a.hover-tile {
    text-decoration: none;
    padding: 0.5em 1em;
}

.hover-tile {
    color: #fff;
    background-color: rgba(0,0,0,0.6);
    box-shadow: 2px 2px 5px rgba(0,0,0,0.4);
    text-shadow: 2px 2px rgba(0,0,0,0);
    transition: all 0.3s;
}

@media screen and (min-width: 641px) {
    .hover-tile:hover {
        background-color: rgba(0,0,0,0);
        box-shadow: 2px 7px 10px rgba(0,0,0,0.4);
        text-shadow: 2px 2px rgba(0,0,0,0.5);
    }
}

.edit-button {
    text-decoration: none;
    vertical-align: super;
    font-size: 50%;
}

@media screen and (max-width: 640px) {
    .hover-tile {
        background-color: rgba(0,0,0,0.3);
        transition: none;
    }
}

h1, h2 {
    font-family: 'Patua One', cursive;
    font-weight: 400;
}

.footer {
    background-color: #111;
    height: 64px;
    flex-shrink: 0;
    margin-top: 4em;
    padding: 0 calc((100% - 1000px) / 2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #999;
}

@media screen and (max-width: 640px) {
    .footer {
        padding: 0 1em;
    }
}